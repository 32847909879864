import React from 'react';
import AchievementSection from './AchievementSection';

export default function index() {
  
  return (
    <div>
      <AchievementSection/>
    </div>
  )
}
