import React from 'react';
import "./Sponsors.css";

export default function index() {
  return (
    <div>
      Sponsors
    </div>
  )
}
